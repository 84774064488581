export const DECOR_LINES_COUNT: number = 6;
export const STYLE_PLAN_LESSONS: number = 50;
export const GUIDES_COUNT: number = 5;
export const MAX_NOTES_COUNT: number = 25;
export const MAX_NOTE_TITLE_LENGTH: number = 30;
export const SKELETONS_STYLE_PLAN_COUNT: number = 9;
export const MAXIMUM_RECENT_ITEMS: number = 3;
export const MAX_PHOTO_SIZE: number = 5121440; // 5MB
export const TRIAL_GUIDE: string = 'trial';
export const CHEAP_GUIDE: string = 'cheap';
export const NAME_REGEX: RegExp = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const ONE_DAY: number = 86400000;

export const ONE_HOUR: number = 3600;
export const ONBOARDING_COLLECTION: string = 'onboardingV2';
export const SESSION_CONTENT = 'sessionContent';
export const ONBOARDING_SCREEN: string = 'onboarding_folder_v2';
export const INTERVIEW_OFFER: string = 'interview_offer';
export const PAID_OPTIONS_STATUS: string = 'paid_notes_feature_toggle';
export const UNSUBSCRIBE_AB_TEST: string = 'unsubscribe_ab_test';
export const SHOW_EXTENDED_ONBOARDING: string = 'web_show_extended_onboarding';
export const DEFAULT_UPSELL_OFFER: string = 'default_upsell_offer';
export const RATING_STARS_COUNT: number = 5;
export const MINIMUM_ACCEPTABLE_RATING: number = 4;
export const PRODUCT_NAME_PART_MONTHLY: string = 'monthly';
export const PRODUCT_NAME_PART_LIFETIME: string = 'lifetime';
export const PRODUCT_NAME_PART_QUARTERLY: string = 'quaterly';
export const PRODUCT_NAME_PART_QUARTERLY_SECOND: string = 'quarterly';
export const DATE_NOW: Date = new Date();
export const WARDROBE_UPSELL_PRODUCT_ID = '7d7a59ec-23ab-442a-a62c-dbc339391c10';
export const SEASONAL_CAPSULE_UPSELL_PRODUCT_ID = 'abb47ec4-b3bc-4c00-aa04-1dfcc1b98812';
export const COLOR_PALETTE_UPSELL_PRODUCT_ID = 'ba96b703-583e-44c4-8a4a-1be85855dbd6';
export const GUIDES_ROWS_COUNT: number = 3;
export const LUMI_PLACE_DOMAIN: string = 'lumi.place';
export const SUPPORT_EMAIL: string = 'support@lumi.place';
export const LUMI_ONLY_SUPPORT_EMAIL: string = 'customer.care@lumi.place';
export const GUIDE_SUBSCRIPTION = 'guide';
export const DO_NOT_SHOW = 'DO_NOT_SHOW';
export const VOUCHER_SUBSCRIPTION = 'voucher';
export const MINIMUM_LIFETIME_DAYS = 8000;
export const DEFAULT_DISCOUNT_PRODUCT_ID = '45877714-ae8e-4916-bf67-32deb8cd56c4';
export const DEFAULT_DISCOUNTED_PRICE = 14.99;
export const MINIMUM_DISCOUNT_PERCENTAGE = 2;
export const SUPPORT_TOKEN = 'Bearer 403761e995ced1a0413198ea416001323b5462f971a7c52f8dfe389f39cd383a';
export const PRODUCT_PATTERN = /\{\{([^{}]+)}}/;
export const ARTICLE_LEGAL_SENTENCE_ID = '2';

// Cloudinary
export const CLOUDINARY_CLOUD_NAME = 'product-title';
export const CLOUDINARY_UPLOAD_PRESET = 'h9hbpnmv';
export const USERS_FOLDER = 'product/web/users';
