export enum LayoutType {
  MAIN = 'MAIN',
  EMPTY = 'EMPTY',
  LANDING = 'LANDING',
}

export enum Fonts {
  TEXT = 'SF Pro Text',
  TITLE = 'SF Pro Display',
  LATO = 'Lato',
}

export enum QueryKey {
  INIT_SESSION = 'init-session',
  USER_DATA = 'user-data',
  USER_PRODUCTS = 'user-products',
  CONTENT_PLAN = 'content-plan',
  USER_PROGRESS = 'user-progress',
  USER_GUIDE = 'user-guide',
  BUY_GUIDE = 'buy-guide',
  USER_SUBSCRIPTION_INFO = 'user-subscription-info',
  CHANGE_PASSWORD = 'change-password',
  SIGN_IN = 'sign-in',
  RENEW_SUBSCRIPTION = 'renew-subscription',
  SET_NEW_MEET = 'set-new-meet',
  ONBOARDING = 'onboarding',
  INTERVIEW_OFFER = 'interview-offer',
  PAID_OPTIONS_STATUS = 'paid-options-status',
  LAST_USER_SESSION = 'last-user-session',
  EDIT_MEET = 'edit-meet',
  CHARGE_REMINDER = 'charge-reminder',
  UNSUBSCRIBE_AB_TEST = 'unsubscribe-ab-test',
  FIREBASE_REMOTE_CONFIG = 'firebase-remote-config',
  BUY_UPSELL = 'buy-upsell',
  SET_SESSION_LIMIT = 'set-session-limit',
  USER_SESSION_LIMIT = 'session-limit',
  SESSION_CONTENT = 'session-content',
  SUBSCRIPTION_DISCOUNTS = 'subscription-discounts',
  USER_SUBSCRIPTIONS_INFO = 'user-subscriptions-info',
  ARTICLE = 'article',
  HIGHLIGHTS = 'highlights',
  STYLE_ARTICLE = 'style-article',
  LEGAL_SENTENCE = 'legal-sentence',
  USER_PHOTO = 'user-photo',
  OUTFIT = 'outfit',
  SAVED_OUTFITS = 'saved-outfits',
  LIKE_OUTFIT = 'like-outfit',
  CONFIG = 'config',
}

export enum ProductNameDiscount {
  PREMIUM = '39ad6435-e720-4240-9ad8-fb1086aa28e5',
  TRIAL = '39ad6435-e720-4240-9ad8-fb1086aa28e5',
  ONE_MONTH_FREE = '55b38017-c1e6-4503-9caa-e920ce4f3772',
}

export enum UserSource {
  WEB = 'web',
  IOS = 'ios',
  POLICY = 'policy',
}

export enum FirebaseCollection {
  USER_PROFILE_MAPPING = 'userProfileMapping',
}

export enum FirebaseType {
  LUMI = 'lumi',
  TITLE = 'title',
}

export type TCloudinaryResponse = {
  asset_id?: string;
  public_id?: string;
  version?: number;
  version_id?: string;
  signature?: string;
  width?: number;
  height?: number;
  format?: string;
  resource_type?: string;
  created_at?: string;
  tags?: string[];
  bytes?: number;
  type?: string;
  etag?: string;
  placeholder?: boolean;
  url?: string;
  secure_url?: string;
  original_filename?: string;
};
