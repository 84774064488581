import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { HttpCodeSessionException, IEditSessionData, QueryKey, RoutePaths } from 'common-types';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useAnalytics } from 'services/analytics';
import chicApi from 'services/chic-api';

interface IEditMeet {
  meetId: string;
  data: IEditSessionData;
}

export const useEditMeet = (): UseMutationResult<unknown, unknown, IEditMeet, unknown> => {
  const { trackEvent } = useAnalytics();
  const location = useLocation();

  return useMutation([QueryKey.EDIT_MEET], ({ meetId, data }: IEditMeet) => chicApi.editSession(meetId, data), {
    onError: (error: AxiosError<{ code: number }>) => {
      trackEvent('booking_screen_api_error', {
        error: 'Request failed',
        placement: location.pathname === RoutePaths.BOOKING ? 'onboarding' : 'stylist_screen',
        type: 'edit',
      });

      if (error?.response?.data?.code === HttpCodeSessionException.SESSION_LIMIT) {
        toast.error('You have the maximum number of booked sessions');
        return;
      }
      if (error?.response?.data?.code === HttpCodeSessionException.SESSION_IS_STARTED) {
        toast.error('Session is already started');
        return;
      }
      if (
        error?.response?.data?.code === HttpCodeSessionException.SESSION_SLOT ||
        error?.response?.data?.code === HttpCodeSessionException.SESSION_NO_SLOT
      ) {
        toast.error('Someone has already booked this slot. Please, choose another time and try again');
        return;
      } else {
        toast.error('Something went wrong, please try again', {
          icon: ErrorOutlineIcon,
        });
      }
    },
  });
};
