import axios, { AxiosInstance } from 'axios';
import {
  GuideType,
  IBookingSession,
  IProduct,
  IStyleGuide,
  IUser,
  IUserData,
  IUserSubscriptionInfo,
  OrderPaymentsData,
  PaymentMethod,
  SubscriptionStatus,
  TSubscriptionDiscount,
  TUserPhoto,
  TUserSubscriptionsInfo,
} from 'common-types';

import { useLocalStorageStore } from '../../store';
import { SERVER_URL, SUBSTITUTION_DOMAIN } from './config';

interface IHttpClient {
  get<Response>(url: string, config?: any): Promise<{ data: Response }>;

  put<Request, Response>(url: string, request: Request, config?: any): Promise<{ data: Response }>;

  post<Request, Response>(url: string, request: Request, config?: any): Promise<{ data: Response }>;
}

export interface OrderProduct {
  name: 'trial' | 'style' | 'body' | 'hair';
  payment_provider?: PaymentMethod;
}

export interface OrderResponse {
  id: string;
  email?: string;
  userName?: string;
  status?: SubscriptionStatus;
  products: OrderProduct[];
  retest?: boolean;
  attempts?: number;
  schema?: Record<string, any>;
  subscription?: {
    active: true;
  };
}

export interface UserGeoDataResponse {
  country: string;
  currency: string;
  ip: string;
}

interface IApi {
  /**
   * @description service method for checking payment status for common and additional style guides
   * by searching inside products array
   */

  signIn: (payload: { email: string; password: string }) => Promise<string>;

  resetPassword: (email: string) => Promise<void>;

  // renewSubscription: (userId: string) => Promise<void>;

  setStylistMeet: (data: IBookingSession) => Promise<void>;

  /**
   * @description sets jwt token it into global httpClient header
   * and EventSource instance
   */
  setAuth: (token: string, id?: string) => void;

  /**
   * @description returns jwt auth token from response and moves it into setAuth method
   */
  initSession: () => Promise<string>;

  /**
   * @description change subscription for user
   * @todo describe response data
   */
  // subscriptionChange: (userId: string, newProductName: string, domain: string) => Promise<unknown>;

  /**
   * @description resets current user session, removes session id cookie
   */
  restoreSession: (id: string) => Promise<string>;

  buyGuide: (productName: GuideType) => Promise<OrderPaymentsData[]>;

  getSubscriptionStatus: (email: string) => Promise<any>;

  getUserSubscriptionInfo: (
    subscriptionId: string,
    userId: string,
    userDomain: string,
  ) => Promise<IUserSubscriptionInfo>;

  getUserGuides: (userId: string) => Promise<IProduct[]>;

  /**
   * @description change password
   */
  changePassword: (email: string, newPassword: string, password: string) => Promise<void>;

  /**
   * @description cancel subscription user
   */
  // unsubscribeUser: (userId: string, domain: string) => Promise<void>;

  setProductNotification: (userId: string, guide: string) => Promise<void>;

  getUserInfo: () => Promise<IUser>;

  setUserInfo: (userData: IUserData, id: string) => Promise<IUserData>;

  setUserPhoto: (data: TUserPhoto, id: string) => Promise<TUserPhoto>;

  /**
   * @description set user style plan progress
   */
  setUserProgress: (userProgress: IUser, id: string) => Promise<any>;

  /**
   * @description get user style guide
   */
  getStyleGuide: (id: string) => Promise<IStyleGuide>;

  getGuide: (type: string, id: string, domain: string) => Promise<Blob>;
}

/**
 * @todo refactor into smaller files
 */
class Api implements IApi {
  private readonly substitutionDomain = SUBSTITUTION_DOMAIN;

  private readonly host = encodeURIComponent(this.substitutionDomain || 'account.lumi.place');

  public constructor(
    private readonly httpClient: IHttpClient = axios.create(),
    private readonly baseUrl = SERVER_URL,
  ) {}

  private eventSource: any;

  // public methods

  public setAuth: IApi['setAuth'] = (token: string, id?: string) => {
    const userId = useLocalStorageStore.getState().userId;
    this.setGlobalHeader('User-Id', userId || id);
    this.setGlobalHeader('Authorization', `Bearer ${token}`);
  };

  public initSession: IApi['initSession'] = async () => {
    const { token } = await this.get<{ token: string }>('/session');
    if (token) this.setAuth(token);
    return token;
  };

  public restoreSession: IApi['restoreSession'] = async (id) => {
    const { token } = await this.get<{ token: string }>(`/session/${id}`);
    if (token) this.setAuth(token);
    return token;
  };

  public getUserGuides: IApi['getUserGuides'] = async (userId) => this.get(`/products/${userId}`);

  public getStyleGuide: IApi['getStyleGuide'] = async (userId) => this.get(`/styleguide/${userId}`);

  public getGuide: IApi['getGuide'] = async (type, id, domain) =>
    this.get(`/guide/${type}/${id}?domain=${domain}`, {
      responseType: 'blob',
      headers: { ContentType: 'application/pdf' },
    });

  public setProductNotification: IApi['setProductNotification'] = async (userId, guide) =>
    this.post(`/products/notification`, { userId, guide });

  public getUserInfo: IApi['getUserInfo'] = async () => this.get('/me');

  public setUserInfo: IApi['setUserInfo'] = async (userData, id) => this.post(`/user/${id}`, userData);

  public setUserPhoto: IApi['setUserPhoto'] = async (data, id) => this.post(`/user/${id}/photo`, data);

  public setUserProgress: IApi['setUserProgress'] = async (userData, id) =>
    this.post<any, any>(`/user/${id}`, { userData });

  // public subscriptionChange: IApi['subscriptionChange'] = async (userId, newProductName, domain) =>
  //   this.post<any, any>(`/subscription-change?domain=${domain}`, { userId, productId: newProductName });

  public buyGuide: IApi['buyGuide'] = async (productName) => this.post('/additional-form', { productName });

  public getSubscriptionStatus: IApi['getSubscriptionStatus'] = async (email) => {
    const status = await this.get(`/subscription?domain=${this.host}&email=${email}`);
    return status;
  };

  public getUserSubscriptionInfo: IApi['getUserSubscriptionInfo'] = async (subscriptionId, userId, userDomain) =>
    await this.get(`/app/subscription/${subscriptionId}?userId=${userId}&domain=${userDomain}`);

  public getSubscriptionDiscounts = async (): Promise<Record<string, TSubscriptionDiscount>> => {
    return this.get('/products/discounts');
  };

  public signIn: IApi['signIn'] = async (payload) => {
    const { token, userId } = await this.post<Record<string, string>, { token: string; userId: string }>(
      '/signin',
      payload,
    );
    if (token) this.setAuth(token, userId);
    return token;
  };

  public changePassword: IApi['changePassword'] = async (email, newPassword, password) =>
    this.post<any, any>('/change-password', { email, newPassword, password });

  // public unsubscribeUser: IApi['unsubscribeUser'] = async (userId, domain) =>
  //   this.post<any, any>(`/subscription-cancel/user?domain=${domain}`, { userId });

  public resetPassword: IApi['resetPassword'] = async (email) =>
    this.post<Record<string, string>, void>('/forgot', { email });

  // public renewSubscription: IApi['renewSubscription'] = async (userId) =>
  //   this.post<Record<string, string>, void>(`/subscription/renew`, { userId });

  public setStylistMeet: IApi['setStylistMeet'] = async (data) => this.post('/stylist-meet', data);

  public getUserSubscriptionsInfo = async (userId: string): Promise<TUserSubscriptionsInfo[]> =>
    this.get(`/app/subscriptions/${userId}`);

  // private methods
  private getUrl = (url: string): string =>
    `${this.baseUrl}${url}${url.includes('domain=') ? '' : `?domain=${this.host}`}`;

  private async get<Response>(url: string, config?: any): Promise<Response> {
    const response = await this.httpClient.get<Response>(this.getUrl(url), config);
    return response.data;
  }

  private async put<Data, Response>(url: string, data: Data): Promise<Response> {
    const response = await this.httpClient.put<Data, Response>(this.getUrl(url), data);
    return response.data;
  }

  private async post<Data, Response>(url: string, data: Data): Promise<Response> {
    const response = await this.httpClient.post<Data, Response>(this.getUrl(url), data);
    return response.data;
  }

  private setGlobalHeader(headerName: string, value: string): void {
    (this.httpClient as AxiosInstance).defaults.headers.common[headerName] = value;
  }
}
export default new Api();
