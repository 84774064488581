import { EConfigType } from 'common-types/config';
import { StateCreator } from 'zustand';

import { TConfigStore } from './types';

export const configSlice: StateCreator<TConfigStore, [], [], TConfigStore> = (set, get) => ({
  config: null,
  setConfig: (config) => set({ config }),
  isEnable: (name): boolean => {
    const config = get()?.config;

    return config?.find((c) => c?.name === name)?.enabled;
  },
  isMembershipPromoCodeEnabled: (): boolean => {
    return get()?.isEnable(EConfigType.MEMBERSHIP_PROMO_CODE) ?? false;
  },
});
