export enum EConfigType {
  UNLIMITED_SESSION_REGULAR_SESSION = 'unlimited_session_regular_session',
  MEMBERSHIP_PROMO_CODE = 'membership_promoCode',
}

export type TConfig = {
  count: number;
  data: TConfigData[];
};

export type TConfigData = {
  id: string;
  name: EConfigType;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
};
