import { create } from 'zustand';

import { configSlice } from './config';
import { dynamicUpsellsSlice } from './dynamic-upsells';
import { errorSlice } from './error';
import { guidesSlice } from './guides';
import { localStorageSlice } from './localstorage-data';
import { onboardingSlice } from './onboarding';
import { paymentsSlice } from './payments';
import { reviewSlice } from './review';
import { sessionsSlice } from './sessions';
import { sessionStorageSlice } from './sessionstorage-data';
import { subscriptionsSlice } from './subscriptions';
import { ILocalStorageStore, ISessionStorageStore, StoreType, TConfigStore } from './types';
import { unsubscribeSlice } from './unsubscribe';
import { unsubscribeSimplifiedSlice } from './unsubscribe-simplified';
import { userSlice } from './user';

export const useStore = create<StoreType>((...store) => ({
  ...userSlice(...store),
  ...paymentsSlice(...store),
  ...unsubscribeSlice(...store),
  ...onboardingSlice(...store),
  ...guidesSlice(...store),
  ...reviewSlice(...store),
  ...sessionsSlice(...store),
  ...errorSlice(...store),
  ...dynamicUpsellsSlice(...store),
  ...subscriptionsSlice(...store),
  ...unsubscribeSimplifiedSlice(...store),
}));

export const useLocalStorageStore = create<ILocalStorageStore>((...store) => ({
  ...localStorageSlice(...store),
}));

export const useSessionStorageStore = create<ISessionStorageStore>((...store) => ({
  ...sessionStorageSlice(...store),
}));

export const useConfigStore = create<TConfigStore>((...store) => ({
  ...configSlice(...store),
}));
